import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { API_BASEURL } from "utils/constants";

const LanguageContext = createContext();

export const useLanguageContext = () => {
    const context = useContext(LanguageContext);
    if (context === undefined) {
        throw new Error(
            "LanguageContext must be used within a LanguageProvider"
        );
    }
    return context;
};

let language = "";
let myVal = localStorage.getItem("user_details");
myVal = JSON.parse(myVal);
if (myVal?.language_settings?.staff_lang?.language?.length) {
    language = myVal?.language_settings?.staff_lang?.language;
} else if (myVal?.language_settings?.clinic_lang?.language?.length) {
    language = myVal?.language_settings?.clinic_lang?.language;
} else if (myVal?.language_settings?.restricted_lang?.language?.length) {
    language = myVal?.language_settings?.restricted_lang?.language;
}

export const LanguageProvider = ({ children }) => {
    const [translatedPageData, setTranslatedPageData] = useState({});
    const [languageType, setLanguageType] = useState(language);
    const [pagePath, setPagePath] = useState("");
    const [isArabic, setIsArabic] = useState(true); //
    const [isLanguageLoading, setIsLanguageLoading] = useState(false);

    useEffect(() => {
        setIsLanguageLoading(true);
        if (languageType === "ar") setIsArabic(true);
        else setIsArabic(false);
        //   console.log(" languageType:----------- >>", languageType);
        //   console.log(" pagePath: ------------>>", pagePath);
        if (pagePath?.length && languageType?.length) {
            axios
                .get(
                    `${API_BASEURL}/public/intlmessages?page_wise=${pagePath}&locale=${languageType}`
                )
                .then(({ data }) => {
                    //   console.log(".then ~ data: >>", data[pagePath]);
                    setTranslatedPageData(data[pagePath]);
                })
                .catch(({ response }) => {
                    console.log("language context ~ response: >>", response);
                })
                .finally(() => {
                    setIsLanguageLoading(false);
                });
        }
    }, [languageType, pagePath]);

    return (
        <LanguageContext.Provider
            value={{
                translatedPageData,
                setPagePath,
                isArabic,
                isLanguageLoading,
                setLanguageType,
            }}
        >
            <div dir={isArabic ? "rtl" : "ltr"}>{children}</div>
        </LanguageContext.Provider>
    );
};
