import React, { useState, createContext } from "react";

const PlanCostContext = createContext();

function PlanCostProvider({ children }) {
    const [state, setState] = useState({action:"initial", params:{} });

    return (
        <PlanCostContext.Provider
            value={{
                state,
                setState,
            }}
        >
            {children}
        </PlanCostContext.Provider>
    );
}

export { PlanCostProvider, PlanCostContext };
