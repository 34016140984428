import axios from "axios";
import dayjs from "dayjs";
import {
    API_BASENAME,
    API_BASEPATH,
    API_BASEURL,
    UI_BASEPATH,
} from "./constants";

export const avatarName = (str = "") => {
    return str
        .split(" ")
        .map((word) => word[0]?.toUpperCase())
        .join("");
};
export const convertToPlain = (html) => {
    let strippedHtml = html.replace(/<[^>]+>/g, "");
    return strippedHtml;
};

export const hideScrollBar = () => {
    document.body.style.overflowY = "hidden";
};

export const showScrollBar = () => {
    document.body.style.overflowY = "scroll";
};

export const hasChildren = (item) => {
    const { items: children } = item;

    if (children && children?.length !== 0) return true;
    else return false;
};

export const truncateString = (text, limit = 25) => {
    if (!text || typeof text !== "string") return "";

    return text.slice(0, limit) + (text.length >= 25 ? "..." : "");
};

export const capitalize = (text) => {
    if (!text || typeof text !== "string") return "";

    const words = text.split(" ");
    let capitalizedSentence = "";

    words.forEach((word, wordIndex) => {
        if (!word) return;

        const capitalizedFirstLetter = word[0].toUpperCase();
        const restLetters = word.slice(1);

        capitalizedSentence += capitalizedFirstLetter + restLetters;

        if (wordIndex !== words.length - 1) capitalizedSentence += " ";
    });

    return capitalizedSentence;
};

export const simpleDeepCopy = (data) => {
    return JSON.parse(JSON.stringify(data || "[]"));
};

export const extractTextFromHTML = (html) => {
    if (!html || typeof html !== "string") return "";

    const htmlParser = new DOMParser().parseFromString(html, "text/html");
    const text = htmlParser.body.textContent;

    return text;
};

export const formatDate = (date, format = "MM/DD/YYYY") => {
    return dayjs(date).format(format);
};

export const notAllowInvalidChars = (e, dispatcher) => {
    let { value } = e.target;
    let regex = /[~`%^&*{}[\]"'!@#,.?/\\|]/;
    if (value.trim().length === 0) {
        return dispatcher?.("");
    }
    if (!regex.test(value)) {
        dispatcher?.(value);
    } else dispatcher?.((prev) => prev);
};

export const validatePercentNumber = (e, dispatcher) => {
    // validates the number with 3 digit and two decimal digit...
    //eg.format 100.00 || 99.11
    let { value } = e.target;
    let regex = /^[0-9]{0,2}(\.[0-9]{0,2})?$|^(100)(\.[0]{0,2})?$/;
    if (value.length === 0) {
        return dispatcher?.("");
    }
    if (regex.test(value)) {
        dispatcher?.(value);
    } else dispatcher?.((prev) => prev);
};

export const validateDecimalNumber = (e, dispatcher) => {
    // validates the decimal number with 10 digit and two decimal digit...
    //eg.format 1111111111.11
    let { value } = e.target;
    let regex = /^((?!0)\d{1,10}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/;
    if (value.length === 0) {
        return dispatcher?.("");
    }
    if (regex.test(value)) {
        dispatcher?.(value);
    } else dispatcher?.((prev) => prev);
};

export const makeURL = (urlChunks, queryParams) => {
    if (Array.isArray(urlChunks)) {
        let url =
            `${API_BASEURL}${API_BASEPATH}${API_BASENAME}/` +
            urlChunks.join("/");

        if (typeof queryParams === "object" && queryParams !== null) {
            const queryParamKeys = Object.keys(queryParams);

            if (queryParamKeys.length > 0) {
                url += "?";

                queryParamKeys.forEach((queryParamKey, queryParamIdx) => {
                    url += `${queryParamKey}=${queryParams[queryParamKey]}${
                        queryParamIdx !== queryParamKeys.length - 1 ? "&" : ""
                    }`;
                });
            }
        }

        return url;
    }

    return "";
};

export const statusClrMapping = {
    NOTED: {
        color: "#1699E7",
        bgColor: "rgba(22, 153, 231, 0.1)",
    },
    ON: {
        color: "#28A745",
        bgColor: "rgba(40, 167, 69, 0.1)",
    },
    OFF: {
        color: "#DF514C",
        bgColor: "rgba(223, 81, 76, 0.1)",
    },
    OPEN: {
        color: "#28A745",
        bgColor: "rgba(40, 167, 69, 0.1)",
    },
    CLOSE: {
        color: "#DF514C",
        bgColor: "rgba(223, 81, 76, 0.1)",
    },
    OVERDUE: {
        color: "#DF514C",
        bgColor: "rgba(223, 81, 76, 0.1)",
    },
    CLOSED: {
        color: "#DF514C",
        bgColor: "rgba(223, 81, 76, 0.1)",
    },
    ACTIVE: {
        color: "#28A745",
        bgColor: "rgba(40, 167, 69, 0.1)",
    },
    INACTIVE: {
        color: "#DF514C",
        bgColor: "rgba(223, 81, 76, 0.1)",
    },
    PENDING: {
        color: "#391f48",
        bgColor: "rgba(57, 31, 72, 0.1)",
    },
    PAID: {
        color: "#28A745",
        bgColor: "rgba(40, 167, 69, 0.1)",
    },
    APPROVED: {
        color: "#28A745",
        bgColor: "rgba(40, 167, 69, 0.1)",
    },
    DELETED: {
        color: "#DF514C",
        bgColor: "rgba(223, 81, 76, 0.1)",
    },
    CANCELLED: {
        color: "#1699E7",
        bgColor: "rgba(22, 153, 231, 0.1)",
    },
    CHECKIN: {
        color: "#28A745",
        bgColor: "rgba(40, 167, 69, 0.1)",
    },
    DISCHARGE: {
        color: "#28A745",
        bgColor: "rgba(40, 167, 69, 0.1)",
    },
    WAITING: {
        color: "#1699E7",
        bgColor: "rgba(22, 153, 231, 0.1)",
    },
};

export const debounce = (func, delay) => {
    let inDebounce;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => func?.apply(context, args), delay);
    };
};

export const throttle = (func, limit) => {
    let inThrottle;
    return function () {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
            func?.apply(context, args);
            inThrottle = true;
            setTimeout(() => (inThrottle = false), limit);
        }
    };
};

export const regexes = {
    email: /^\S+@\S+\.\S+$/,
    number: /^\d*\.?\d*$/,
    zipcode: /^\d*-?\d*$/,
    fax: /^(\+)?(((((\d+)|(\(\d+\))|(\(\d+\s(\d)\)))(\s|-|\d+))+)|((\d+)|(\(\d+\))$)+)+\d$/,
    // text: /^[a-zA-Z ][a-zA-Z 0-9]*$/, //"hello" "hello123" "Hello World" or hello 4561
    text: /^[\u4e00-\u9fa5a-zA-Z ][\u4e00-\u9fa5a-zA-Z 0-9]*$/u, // for english and Chinese language
    // name: /^[a-zA-Z ]+$/,
    name: /^[\u4e00-\u9fa5a-zA-Z ]+$/u,
    matchAnything: /^[\s\S]+$/,
    // address: /^[a-zA-Z0-9!@#$()_+:;<>,.* ]+$/, //not allowed  ///[~%^&/?]/
    // address: /[~%^&/?]/,
    address: /^[\u4e00-\u9fa5a-zA-Z0-9!@#$()_+:;<>,.* ]+$/u, //for english and chinese languages
    //  password: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/, //6 to 16 valid characters
    password: /^[\s\S]+$/,
    website: /^(www\.)?[a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?\.[a-z]{2,63}$/,
    // ("www.example-site.com");  // true
    // website:
    // /^(www\.)?[\u4e00-\u9fa5a-zA-Z0-9]([\u4e00-\u9fa5a-zA-Z0-9-]{0,61}[\u4e00-\u9fa5a-zA-Z0-9])?\.[\u4e00-\u9fa5a-z]{2,63}$/u, // for chinese and english website
    // scriptVal: /<(?:script|style)[^>]*>.*?<\/(?:script|style)>|<[^>]*>/g,
    htmlRegex: /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g,
    decimalNo: /^\d+(\.\d+)?$/,
    decimalPositiveNo: /^[0-9]+(\.[0-9]+)?$/,
    percentage: /^(100(\.0{1,2})?|[0-9]{1,2}(\.\d{1,3})?)%$/, // 0.5% true
};

export const getNumberWithOrdinal = (n) => {
    var s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const validateByRegex = (type, value) => {
    // Return true if there is no validation for the type
    return regexes[type]?.test(value) ?? true;
};

export const downloadFile = (url, filename) => {
    let link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
};

export const fileReader = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return reader;
};

export const computedArray = (items = [], obj = {}) => {
    let item = items?.find((item) => item[obj.key] === obj.value);
    let index = items?.findIndex((item) => item[obj.key] === obj.value);
    return [item, index];
};

export const removeArrayDuplicatesByProp = (arr, uniqueProp) => {
    const uniqueProps = arr.map((item) => item[uniqueProp]);
    const uniqueArr = arr.filter((item, index) => {
        return !uniqueProps.includes(item[uniqueProp], index + 1);
    });

    return uniqueArr;
};

export const setItemLocal = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};
export const getItemLocal = (key) => {
    let value = JSON.parse(localStorage.getItem(key));
    return value;
};
export const removeItemLocal = (key) => {
    localStorage.removeItem(key);
};
export const setItemSession = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};
export const getItemSession = (key) => {
    let value = JSON.parse(sessionStorage.getItem(key));
    return value;
};

export const removeItemSession = (key) => {
    sessionStorage.removeItem(key);
};

export const onlyNumberKey = (evt) => {
    var ASCIICode = evt.which ? evt.which : evt.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
};
export const getTableDataForExport = (data = [], columns = []) =>
    data?.map((record) =>
        columns.reduce((recordToDownload, column) => {
            if (column?.id === "serialNo") return {};
            return {
                ...recordToDownload,
                [column?.label]:
                    record[column?.id]?.data ?? record[column?.id] ?? "",
            };
        }, {})
    );

export const makeCsv = async (rows = [], filename = "") => {
    const separator = ";";
    const keys = Object.keys(rows[0]);
    // console.log(keys);

    const csvContent = `${keys.join(separator)}\n${rows
        .map((row) =>
            keys
                .map((k) => {
                    let cell =
                        row[k] === null || row[k] === undefined ? "" : row[k];

                    cell =
                        cell instanceof Date
                            ? cell.toLocaleString()
                            : cell.toString().replace(/"/g, '""');

                    if (cell.search(/("|,|\n)/g) >= 0) {
                        cell = `"${cell}"`;
                    }
                    return cell;
                })
                .join(separator)
        )
        .join("\n")}`;

    const blob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8;",
    });
    if (navigator.msSaveBlob) {
        // In case of IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

export const downloadCsv = async (csvContent = "", filename = "") => {
    const blob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8;",
    });
    if (navigator.msSaveBlob) {
        // In case of IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

export function imageUrlToBase64(imageUrl) {
    try {
        const response = axios.get(imageUrl, { responseType: "arraybuffer" });
        const contentType = response?.headers["content-type"] || "image/jpeg"; // Set default content type
        const blob = new Blob([response?.data], { type: contentType });
        const base64 = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader?.readAsDataURL(blob);
            reader.onload = () => resolve(reader?.result);
            reader.onerror = reject;
        });
        // return base64.split(",")[1];
        return base64;
    } catch (error) {
        console.error(error);
        return "";
    }
}

// export const locale =
//     (navigator.languages && navigator.languages[0]) ||
//     navigator.language ||
//     navigator.userLanguage ||
//     "en-US";

export const flattenMessages = (nestedMessages, prefix = "") => {
    return Object.keys(nestedMessages).reduce((messages, key) => {
        let value = nestedMessages[key];
        let prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === "string") {
            messages[prefixedKey] = value;
        } else {
            Object.assign(messages, flattenMessages(value, prefixedKey));
        }

        return messages;
    }, {});
};

export const sorting = (a, b) => {
    let fa = a.toLowerCase(),
        fb = b.toLowerCase();

    if (fa < fb) {
        return -1;
    }
    if (fa > fb) {
        return 1;
    }
    return 0;
};

export const getUserDetails = () => {
    let user_details = JSON.parse(
        JSON.stringify(localStorage.getItem("user_details"))
    );
    if (user_details && Object.keys(user_details).length > 0) {
        return JSON.parse(user_details);
    } else {
        return {};
    }
};

let menuStackUrls = [];

const getPermissions = async (permissions) => {
    for (let i = 0; i < permissions.length; i++) {
        const elem = permissions[i];
        if (elem.hasOwnProperty("items") && elem.items.length > 0) {
            elem.items.forEach((child, n) => {
                if (!child?.items || child?.items?.length === 0) {
                    menuStackUrls.push(child);
                } else {
                    for (let x = 0; x < child.items.length; x++) {
                        const child_item = child.items[x];
                        if (
                            !child_item?.items ||
                            child_item?.items?.length === 0
                        ) {
                            menuStackUrls.push(child_item);
                        } else {
                        }
                    }
                }
            });
        } else {
            menuStackUrls.push(elem);
        }
    }
};

export const pageActions = async (permissions) => {
    await getPermissions(permissions);
    let tempObj = {};

    await axios
        .get(
            `${process.env.REACT_APP_API_BASEURL}${process.env.REACT_APP_API_BASENAME}/user-groups/actions/fetch/all`
        )
        .then((response) => {
            response.data.forEach((item) => {
                tempObj[item.name] = false;
            });
        });
    let page = menuStackUrls.find(
        (item) => item.path === window.location.pathname
    );
    // console.log("page: ", page);
    if (page) {
        page.actions.forEach((child, i) => {
            tempObj[child.title] = child.allow === true;
        });
    }
    return tempObj;
};
export const pageAllowed = (path) => {
    let allowedURLs = menuStackUrls.map((item) => item.path);
    // console.log(
    //     "res.data.permissions permissionContext: allowedURLs: ",
    //     allowedURLs
    // );
    if (allowedURLs.includes(window.location.pathname)) {
        return true;
    } else {
        return false;
    }
    // return menuStackUrls;
};

export const getAllowedPages = (permissions) => {
    if (permissions && permissions.length > 0) {
        let menuStackUrls = [];
        for (let i = 0; i < permissions.length; i++) {
            const elem = permissions[i];
            if (elem?.hasOwnProperty("items") && elem.items.length > 0) {
                elem.items.forEach((child, n) => {
                    if (!child?.items || child?.items?.length === 0) {
                        menuStackUrls.push(child);
                    } else {
                        for (let x = 0; x < child.items.length; x++) {
                            const child_item = child.items[x];
                            if (
                                !child_item?.items ||
                                child_item?.items?.length === 0
                            ) {
                                menuStackUrls.push(child_item);
                            } else {
                            }
                        }
                    }
                });
            } else {
                menuStackUrls.push(elem);
            }
        }
        return menuStackUrls;
    }
};

export const replacePlacholdersInTemplate = (data, htmlContent) => {
    let re = new RegExp(
        Object.keys(data).join("|").replaceAll("$", "\\$"),
        "g"
    );
    let replace = htmlContent.replace(re, (match) => data[match]);

    return replace;
};

export const setLinkTagProps = (
    path = "/email",
    query = {},
    mailTabParams = () => {}
) => {
    let link = document.createElement("a");
    link.href = `${UI_BASEPATH}${path.substring(1)}`;
    link.search = new URLSearchParams(mailTabParams(query)).toString();
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.click();
};

// let x = [
//     { id: "11-2022", month: 11, year: 2022, data: 6 },
//     { id: "10-2022", month: 10, year: 2022, data: 6 },
//     { id: "9-2022", month: 9, year: 2022, data: 6 },
// ];

// let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
// const now = new Date();
// let res = [];

// for (let i = 0; i > -12; i--) {
//     let future = new Date(now.getFullYear(), now.getMonth() + i, 1);
//     let month = months[future.getMonth()];
//     let year = future.getFullYear();
//     let item = x.find((item) => item.month === month);
//     console.log(month, year);
//     if (item) {
//         res.push(item);
//     } else {
//         res.push({
//             id: month + "-" + year,
//             month: month,
//             year: year,
//             data: 0,
//         });
//     }
// }
// console.log(res);
