import axios from "axios";
import React, { useState, useEffect, createContext, useCallback } from "react";
import { API_BASENAME, API_BASEPATH, API_BASEURL } from "utils/constants";
// import useAuth from "hooks/useAuth";
import { getItemSession, getUserDetails } from "utils/appUtils";

const PermissionContext = createContext();

function PermissionProvider({ children }) {
    // const { isLoggedIn } = useAuth();
    const [loggedIn, setLoggedIn] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [pageDenied, setPageDenied] = useState(false);
    const [loading, setLoading] = useState(true);

    const ugPermissions = useCallback((userGroupId) => {
        setLoading(true);
        axios
            .get(
                `${API_BASEURL}${API_BASEPATH}${API_BASENAME}/user-groups/list/fetch/permissions/${userGroupId}`
            )
            .then(async (res) => {
                setLoading(false);
                setPermissions(res.data.permissions);
                // console.log(
                //     "res.data.permissions permissionContext: ",
                //     res.data.permissions
                // );
            });
    }, []);

    useEffect(() => {
        // need to make dynamic
        const loggedInStatus = getItemSession("_l") ? true : false ?? loggedIn;
        let user_details = getUserDetails();
        // console.log("res user_details: ", user_details);
        let userGroupId = user_details?.isVPAdmin
            ? user_details?.vpadminGroup
            : user_details?.userGroup;
        // console.log("res userGroupId: ", userGroupId);

        if (userGroupId && loggedInStatus) {
            // console.log("res userGroupId if: ", userGroupId);
            ugPermissions(userGroupId);
        }
    }, [ugPermissions, loggedIn]);

    return (
        <PermissionContext.Provider
            value={{
                permissions,
                loggedIn,
                setLoggedIn,
                pageDenied,
                loading,
                setPageDenied,
            }}
        >
            {children}
        </PermissionContext.Provider>
    );
}

export { PermissionProvider, PermissionContext };
