import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { getItemSession } from "utils/appUtils";
import { API_BASENAME, API_BASEPATH, API_BASEURL, API_ENABLED } from "utils/constants";

export default function useGetSessionData() {
    const [sessionData, setSessionData] = useState({
        clinic_session: null,
        provider_session: null,
        client_session: null,
        patient_session: null,
        encounter_session: null,
        otc_client_session: null,
    });
    const [widgetType, setWidgetType] = useState(null);
    const [clinic, setClinic] = useState({});
    const [client, setClient] = useState({});
    const [patient, setPatient] = useState({});
    const [encounter, setEncounter] = useState({});
    const [provider, setProvider] = useState({});
    const [otcClient, setOtcClient] = useState({});
    const [notify, setNotify] = useState({});

    const getSessionDataById = useCallback(
        async (url, dispatcher, responseHandler) => {
            try {
                let { data } = await axios.get(
                    `${API_BASEURL}${API_BASEPATH}${API_BASENAME}/${url}`
                );
                dispatcher(data);
                responseHandler?.(data);
            } catch (err) {
                throw new Error(err);
            }
        },
        []
    );

    useEffect(() => {
        const SESSION = getItemSession("session");
        setSessionData({
            clinic_session: SESSION?.clinic_session,
            provider_session: SESSION?.provider_session,
            client_session: SESSION?.client_session,
            patient_session: SESSION?.patient_session,
            encounter_session: SESSION?.encounter_session,
            otc_client_session: SESSION?.otc_client_session,
        });
    }, []);

    useEffect(() => {
        if (API_ENABLED && sessionData?.clinic_session)
            getSessionDataById(
                `clinic/registration/fetch/session/${sessionData?.clinic_session}`,
                setClinic
            );
    }, [getSessionDataById, sessionData?.clinic_session]);

    useEffect(() => {
        if (API_ENABLED && sessionData?.provider_session)
            getSessionDataById(
                `staff/registration/fetch/${sessionData?.provider_session}?_p=firstName,lastName,sigValue`,
                setProvider
            );
    }, [getSessionDataById, sessionData?.provider_session]);

    useEffect(() => {
        if (
            API_ENABLED &&
            sessionData?.provider_session &&
            sessionData?.clinic_session
        )
            getSessionDataById(
                `communication/dashboard/fetch/notifications`,
                setNotify
            );
    }, [
        getSessionDataById,
        sessionData?.clinic_session,
        sessionData?.provider_session,
    ]);

    useEffect(() => {
        if (API_ENABLED && sessionData?.client_session) {
            getSessionDataById(
                `cp-widget/client/fetch/${sessionData?.client_session}`,
                setClient
            );
            setWidgetType("cpWidgetClient");
        }
    }, [getSessionDataById, sessionData?.client_session]);

    useEffect(() => {
        if (API_ENABLED && sessionData?.patient_session) {
            getSessionDataById(
                `cp-widget/patient/fetch/${sessionData?.patient_session}`,
                setPatient
            );
            setWidgetType("cpWidget");
        }
    }, [getSessionDataById, sessionData?.patient_session]);

    useEffect(() => {
        if (API_ENABLED && sessionData?.encounter_session)
            getSessionDataById(
                `cp-widget/encounter/fetch/${sessionData?.encounter_session}`,
                setEncounter
            );
    }, [getSessionDataById, sessionData?.encounter_session]);

    useEffect(() => {
        if (API_ENABLED && sessionData?.otc_client_session) {
            getSessionDataById(
                `otc/get_otc_clientById/${sessionData?.otc_client_session}`,
                setOtcClient
            );
            setWidgetType("otcWidget");
        }
    }, [getSessionDataById, sessionData?.otc_client_session]);

    return {
        setSessionData,
        setClinic,
        setProvider,
        setPatient,
        setClient,
        setEncounter,
        setWidgetType,
        setOtcClient,
        setNotify,
        sessionData,
        widgetType,
        client,
        patient,
        provider,
        clinic,
        encounter,
        otcClient,
        notify,
    };
}
