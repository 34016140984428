import useGetSessionData from "hooks/useGetSessionData";
import React, { createContext } from "react";

const SessionContext = createContext();

function SessionProvider({ children }) {
    const {
        setSessionData,
        setClinic,
        setClient,
        setPatient,
        setEncounter,
        setOtcClient,
        setProvider,
        setWidgetType,
        setNotify,
        clinic,
        client,
        patient,
        provider,
        encounter,
        otcClient,
        widgetType,
        sessionData,
        notify,
    } = useGetSessionData();
    return (
        <SessionContext.Provider
            value={{
                setSessionData,
                setClinic,
                setClient,
                setPatient,
                setEncounter,
                setOtcClient,
                setProvider,
                setWidgetType,
                setNotify,
                clinic,
                client,
                patient,
                provider,
                encounter,
                otcClient,
                widgetType,
                sessionData,
                notify,
            }}
        >
            {children}
        </SessionContext.Provider>
    );
}

export { SessionProvider, SessionContext };
