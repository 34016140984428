import { getItemSession } from "./appUtils";

export const screens = {
    small: 640,
    tablet: 768,
    laptop: 1024,
    desktop: 1280,
};
export const SSE_ENABLED = true;
export const API_BASEURL = process.env.REACT_APP_API_BASEURL;
export const API_BASENAME = process.env.REACT_APP_API_BASENAME;
export const API_ENABLED =
    !process.env.REACT_APP_API_ENABLED ||
    process.env.REACT_APP_API_ENABLED === "false"
        ? false
        : true;
export const UI_BASEPATH = process.env.REACT_APP_UI_BASEPATH;
export const API_BASEPATH = process.env.REACT_APP_API_BASEPATH;
export const REFERRER = getItemSession("referrer");
export const MAX_FILE_SIZE = 1024 * 1024 * 5; // 5mb
export const MAX_FILE_SIZE_IN_MB = 5;
