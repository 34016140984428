import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Box } from "@mui/material";
import LinearProgress from "./UIComponents/Progress";
import "./index.scss";
import { PermissionProvider } from "context/permissionContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SessionProvider } from "context/SessionContext";
import { PlanCostProvider } from "context/PlanCostModalContext";
import { LanguageProvider } from "context/LanguageContext";

const LazyApp = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));

// for getting rid of the 'Cannot have two html5 backends at the same time' bug
const GlobalDnDProvider = (props) => {
    return <DndProvider backend={HTML5Backend}>{props.children}</DndProvider>;
};

root.render(
    // <React.StrictMode>
    <Suspense
        fallback={
            <Box sx={{ width: "100%" }}>
                <LinearProgress />
            </Box>
        }
    >
        <PermissionProvider>
            <GlobalDnDProvider>
                <SessionProvider>
                    <PlanCostProvider>
                        <LanguageProvider>
                            <LazyApp />
                        </LanguageProvider>
                    </PlanCostProvider>
                </SessionProvider>
            </GlobalDnDProvider>
        </PermissionProvider>
    </Suspense>
    // </React.StrictMode>
);
